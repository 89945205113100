import React from 'react'
import SEO from "../common/SEO";
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderOne from '../common/header/HeaderOneNew';
import FooterFour from '../common/footer/FooterFour';
import BrandSix from '../elements/brand/BrandSix';
import AboutFour from '../elements/about/AboutFour';
import Contact from '../elements/contact/ContactNew';
import ServiceFour from "../elements/service/ServiceFour";
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import Font from '../assets/css/font.css';
import { FiArrowRight, FiCheck } from "react-icons/fi";
import '../assets/scss/style.css';
import {Helmet} from "react-helmet";

const AboutUs = () => {
    return (
        <>
            <Helmet>
                <title>Data Architects - Experts in AI and Data Services</title>
                <meta name="description" content="Experts in Huggingface, Pytorch, Tensorflow, Image Synthesis, Computer Vision, Reinforcement learning, Transformer, Generative AI, Chatgpt, OpenAI" />
                <link rel="icon" type="image/png" href="/images/logo/logo-favicon.png" sizes="16x16" />
            </Helmet>
            {/* <SEO title="home" /> */}
            <main className="page-wrapper">
                <div id="home-section"></div>
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                {/* <div className="slider-area slider-style-1 height-custom-100 bg_image" data-black-overlay="15" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-05.jpg)`}}> */}
                <div className="slider-area slider-style-1 height-custom-100 bg_image" data-black-overlay="15" style={{backgroundImage: `url(/images/bg/bg-05.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 content-block-outer">
                                <div className="inner content-block-features">
                                    {/* <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">Transforming Data into Decisions: AI & Analytics Consulting</span></h3>
                                    </div> */}
                                    <h1 className="title content-feature-headline">Transforming Data into Decisions: AI & Analytics Consulting</h1><br/>
                                    <div className="custom-font-3 home-banner-bullet" style={{display: 'block'}}>
                                        <div className="content-feature-point-block">
                                            <span className="icon-custom-1"><FiCheck /></span><b>One-stop shop for all AI and data analytics software development</b>
                                        </div>
                                    </div>
                                    <div className="custom-font-3 home-banner-bullet" style={{display: 'block'}}>
                                        <div className="content-feature-point-block">
                                            <span className="icon-custom-1"><FiCheck /></span> <b>Requirements to Production - End to End Lifecycle</b>
                                        </div>
                                    </div>
                                    <div className="custom-font-3 home-banner-bullet" style={{display: 'block'}} hidden>
                                        <div className="content-feature-point-block">
                                            <span className="icon-custom-1"><FiCheck /></span> <b>Help businesses reduce cost through intelligent automation</b>
                                        </div>
                                    </div>
                                </div>
                                <div className="inner expert-block-outer text-center">
                                    {/* <h3 className="rn-sub-badge mt--40"><span className="theme-gradient">@ExpertsIn</span></h3> */}
                                    <h3 className="mt--30"><span className="expert-title">@Experts</span></h3>
                                    {/* <hr style={{borderTop: "1px dashed white"}}/> */}
                                    <div className="mt--20 mb--70" style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', 
                                        maxWidth: '90%', margin: '0 auto', padding: 5, paddingTop: 0, background: '', opacity: 0.9, borderRadius: 10}}>
                                        <h3 className="pl--10 pr--10" style={{wordBreak: 'unset', fontSize: 22}}><span className="theme-gradient-1">Huggingface</span></h3>
                                        <h3 className="pl--10 pr--10" style={{wordBreak: 'unset', fontSize: 22}}><span className="theme-gradient-1">Pytorch</span></h3>
                                        <h3 className="pl--10 pr--10" style={{wordBreak: 'unset', fontSize: 22}}><span className="theme-gradient-1">Tensorflow</span></h3>
                                        <h3 className="pl--10 pr--10" style={{wordBreak: 'unset', fontSize: 22}}><span className="theme-gradient-1">Image Synthesis</span></h3>
                                        <h3 className="pl--10 pr--10" style={{wordBreak: 'unset', fontSize: 22}}><span className="theme-gradient-1">Computer Vision</span></h3>
                                        <h3 className="pl--10 pr--10" style={{wordBreak: 'unset', fontSize: 22}}><span className="theme-gradient-1">Reinforcement learning</span></h3>
                                        <h3 className="pl--10 pr--10" style={{wordBreak: 'unset', fontSize: 22}}><span className="theme-gradient-1">Transformer</span></h3>
                                        <h3 className="pl--10 pr--10" style={{wordBreak: 'unset', fontSize: 22}}><span className="theme-gradient-1">Generative AI</span></h3>
                                        <h3 className="pl--10 pr--10" style={{wordBreak: 'unset', fontSize: 22}}><span className="theme-gradient-1">Chatgpt</span></h3>
                                        <h3 className="pl--10 pr--10" style={{wordBreak: 'unset', fontSize: 22}}><span className="theme-gradient-1">OpenAI</span></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="button-group mt--40 mt_sm--20" style={{position: 'fixed', bottom: 50, right: 50, zIndex: 999}}>
                    <a className="btn-default" style={{background: '#726464'}} target="_blank" href="#">Request Consultation</a>
                </div> */}
                {/* End Slider Area  */}


                {/* Start Service Area  */}
                {/* <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">We are creative digital agency working for our company brands.</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, quis. Ullam accusantium dignissimos repellendus nemo fugiat numquam, nisi odio adipisci. Veniam neque itaque expedita officiis rem ipsa! Ratione, rem reiciendis?</p>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Service Area  */}



                {/* Start Brand Area  */}
                <div className="rwt-brand-area pb--60 pt--30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandSix brandStyle="brand-style-1" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}


                <Separator />

                {/* Start Service Area  */}
                {/* <div className="rn-service-area rn-section-gap"> */}
                <div id="service-section"></div>
                <div className="rn-service-area" style={{paddingBottom: 100, paddingTop: 50}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "&nbsp;"
                                    // title = "Services provide for you."
                                    title = "Our Expertise"
                                    // description = "There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                    />
                            </div>
                        </div>
                        <ServiceFour
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                            textAlign = "text-center"
                            />
                        
                    </div>
                </div>

                {/* <div className="rn-service-area" style={{paddingBottom: 100, paddingTop: 50}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "@SERVICES"
                                    // title = "Services provide for you."
                                    title = "Our Expertise"
                                    // description = "There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                    />
                            </div>
                        </div>
                        <ServiceOne
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                            textAlign = "text-center"
                            />
                        
                    </div>
                </div> */}
                {/* End Service Area  */}

                {/* <div className="rwt-timeline-area rn-section-gapBottom"> */}
                <div className="rwt-timeline-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <TimelineTwo classVar="no-gradient"  />
                            </div>
                        </div>
                    </div>
                </div>

                {/* <Separator /> */}

                {/* <AboutFour image="./images/about/contact-image.jpg" /> */}
                
                {/* Start Elements Area  */}
                {/* <div className="rwt-counterup-area pb--100">
                    <div className="container mt_dec--30">
                        <CounterUpFour column="col-lg-3 col-md-6 col-sm-6 col-12" counterStyle="counter-style-4" textALign="text-center" />   
                    </div>
                </div> */}
                {/* End Elements Area  */}

                {/* <Separator /> */}

                {/* Start Elements Area  */}
                {/* <div className="rwt-team-area rn-section-gap"> */}
                <div id="team-section"></div>
                <div className="rwt-team-area" style={{paddingTop: 50}}>
                    <div className="container">
                        <div className="row mb--20">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "&nbsp;"
                                    title = "Our Team"
                                    description = ""
                                />
                            </div>
                        </div>
                        <TeamFour column="col-lg-6 col-xl-3 col-md-6 col-12 mt--50" teamStyle="team-style-three" />
                    </div>
                </div>
                {/* End Elements Area  */}

                <div id="contact-section"></div>
                <Contact/>                
                <FooterFour />
            </main>
        </>
    )
}

export default AboutUs;
